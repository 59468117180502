/* src/components/Home.css */
.home {
    max-width: calc(100% - 0px); /* Adjust width to account for scrollbar */
    margin: 0 auto;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

.home-hero {
    max-width: 1200px;
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: left;
    margin: 20px auto;
    padding: 0;
}

.hero-content {
    width: 100%;
}

.home-hero h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: black;
}

.home-hero .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    font-size: 1.25rem;
    padding: 10px 20px;
}

.home-hero img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
}
