/* Main Contact Page */
.contact-page {
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    padding-top: 20px;
}

.background-svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.background-svg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.contact-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    padding: 20px;
}

/* Contact Form and Text */
.contact-form-container {
    max-width: 600px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px 10px 10px 10px; /* Reduced left and right padding */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-right: 20px;
}

.contact-text {
    max-width: 500px;
    width: 100%;
    color: #333;
    font-size: 1.2rem;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Form Fields */
.form-group {
    margin-bottom: 15px;
}

.form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.form-control.message {
    height: 200px;
}

.form-control.message-small {
    height: 100px;
}

.text-danger {
    color: #dc3545;
    font-size: 14px;
}

.text-success {
    color: #28a745;
    font-size: 14px;
}

.btn {
    display: inline-block;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.btn:hover {
    background-color: #0056b3;
}

/* Small devices (576px to 767px) */
@media (max-width: 767px) {
    .contact-content {
        flex-direction: column; /* Stack form and text vertically */
        align-items: center;
    }

    .contact-form-container {
        max-width: 100%;
        margin: 0 0 20px 0;
        padding: 15px;
    }

    .text-wrapper {
        display: flex;
        justify-content: center; /* Center the text container */
        width: 100%;
    }

    .contact-text {
        width: 350px; /* Fixed width for text container */
        margin: 0 auto; /* Center it inside the parent container */
        padding: 15px;
        background-color: rgba(255, 255, 255, 0.8);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
    }
}

/* Extra Small devices (<576px) */
@media (max-width: 575px) {
    .contact-content {
        flex-direction: column;
        align-items: center;
    }

    .contact-form-container {
        max-width: 100%;
        margin: 0 0 20px 0;
        padding: 10px;
    }

    .text-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .contact-text {
        width: 350px; /* Keep the fixed width */
        margin: 0 auto;
        padding: 10px;
        background-color: rgba(255, 255, 255, 0.8);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
    }
}
