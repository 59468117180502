.services-overview {
    text-align: center;
    padding: 20px;
    margin-bottom: 50px;
}

.services-overview h2 {
    margin-bottom: 50px;
    font-size: 2.8rem;
}

.services-icons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.services-icon {
    width: 170px;
    height: 170px;
    margin: 0 55px;
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease, border 0.3s ease, box-shadow 0.3s ease;
    background-color: transparent;
    border: 2px solid transparent;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.services-icon:hover {
    transform: scale(1.05);
    background-color: rgba(0, 123, 255, 0.1);
}

.services-icon.active {
    transform: scale(1.1);
    background-color: #ffffff;
    border: 2px solid #007bff;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
    pointer-events: none;
}

.services-content {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.services-item-detail {
    background-color: white;
    padding: 20px;
    max-width: 800px;
    width: 100%;
    text-align: center;
}

.services-item-detail h3 {
    font-size: 1.8rem;
    color: #777;
    margin-bottom: 10px;
}

.services-item-detail p {
    font-size: 1.1rem;
    color: black;
}

/* Responsive Adjustments */

/* Medium devices (768px to 991px) */
@media (max-width: 991px) {
    .services-overview h2 {
        font-size: 2.4rem;
    }

    .services-icons {
        flex-wrap: wrap;
    }

    .services-icon {
        margin: 10px;
        width: 150px;
        height: 150px;
    }

    .services-item-detail h3 {
        font-size: 1.6rem;
    }

    .services-item-detail p {
        font-size: 1rem;
    }
}

/* Small devices (576px to 767px) */
@media (max-width: 767px) {
    .services-overview h2 {
        font-size: 1.8rem;
        margin-bottom: 20px;
        text-align: center;
    }

    .services-icons {
        flex-direction: row;
        justify-content: center;
        margin-bottom: 20px;
    }

    .services-icon {
        width: 80px;
        height: 80px;
        margin: 5px 10px;
    }

    .services-item-detail {
        text-align: left;
        padding: 15px;
    }

    .services-item-detail h3 {
        font-size: 1.4rem;
        margin-bottom: 10px;
        text-align: center; /* Center description header on small devices */
    }

    .services-item-detail p {
        font-size: 0.9rem;
    }
}

/* Extra small devices (<576px) */
@media (max-width: 575px) {
    .services-overview h2 {
        font-size: 1.5rem;
        margin-bottom: 10px;
        text-align: center;
    }

    .services-icons {
        flex-direction: row;
        justify-content: center;
        margin-bottom: 10px;
    }

    .services-icon {
        width: 70px;
        height: 70px;
        margin: 5px 8px;
    }

    .services-item-detail {
        padding: 10px;
        text-align: left;
    }

    .services-item-detail h3 {
        font-size: 1.2rem;
        text-align: center; /* Center description header on extra small devices */
    }

    .services-item-detail p {
        font-size: 0.85rem;
    }
}
