.services-hero-section {
    background-image: url('background.svg');
    background-size: cover;
    background-position: center;
    padding: 150px 20px;
    text-align: center;
    color: white;
    margin-bottom: 0px;
    position: relative;
    border: none;
    border-radius: 0;
    width: 100vw;
    margin-left: calc(-50vw + 50%); /* Full-width centering */
}

.services-hero-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
}

.services-hero-content {
    position: relative;
    z-index: 2;
}

.services-hero-section h1 {
    font-size: 3rem;
    margin-bottom: 20px;
    /* Keep h1 color unchanged */
}

.services-hero-section p {
    font-size: 1.5rem;
    margin-bottom: 30px;
    color: white; /* Explicitly set p elements to white */
}

.services-btn-primary {
    padding: 10px 20px;
    background-color: #d3d3d3;
    color: #04283A;
    font-size: 1.2rem;
    border-radius: 5px;
    font-weight: normal;
    text-decoration: none;
    transition: background-color 0.3s ease;
    border: none;
}

.services-btn-primary:hover {
    background-color: #a9a9a9;
    color: #04283A;
}

/* Responsive Adjustments */

/* Large devices (992px and above) */
@media (max-width: 1199px) {
    .services-hero-section h1 {
        font-size: 2.5rem;
    }

    .services-hero-section p {
        font-size: 1.3rem;
    }

    .services-btn-primary {
        font-size: 1rem;
    }
}

/* Medium devices (768px to 991px) */
@media (max-width: 991px) {
    .services-hero-section h1 {
        font-size: 2rem;
    }

    .services-hero-section p {
        font-size: 1.1rem;
    }

    .services-btn-primary {
        font-size: 0.9rem;
    }
}

/* Small devices (576px to 767px) */
@media (max-width: 767px) {
    .services-hero-section {
        background-image: url('background2.svg');
        padding: 100px 20px;
    }

    .services-hero-section h1 {
        font-size: 1.8rem;
    }

    .services-hero-section p {
        font-size: 1rem;
    }

    .services-btn-primary {
        font-size: 0.85rem;
    }
}

/* Extra small devices (<576px) */
@media (max-width: 575px) {
    .services-hero-section {
        background-image: url('background2.svg');
        padding: 80px 15px;
    }

    .services-hero-section h1 {
        font-size: 1.6rem;
    }

    .services-hero-section p {
        font-size: 0.9rem;
    }

    .services-btn-primary {
        font-size: 0.8rem;
    }
}
