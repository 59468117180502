/* Base styles for all screen sizes */
.navbar-container {
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 20px;
}

.navbar-brand img {
    max-height: 80px;
    height: 80px;
    width: auto;
    margin-right: 10px; /* Add space between logo and links */
}

.navbar-links {
    display: flex;
    align-items: center; /* Align the links vertically with the logo */
}

.navbar-nav .nav-link {
    color: #ffffff;
    margin-left: 15px;
}

.navbar-nav .nav-link:hover {
    color: #cccccc;
}
