.services-company-overview {
    margin-bottom: 0; /* Reduce the gap between sections */
    padding: 60px 5%; /* Add more padding and adjust the sides */
    background-color: white; /* Set background to white */
    width: 100vw;
    margin-left: calc(-50vw + 50%);
    border: none;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.services-company-container {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Distribute space between text and image */
    width: 100%;
    max-width: 1200px; /* Control the width of the content */
}

.services-company-text {
    flex: 1;
    padding: 20px;
    text-align: left; /* Left-justify the text */
}

.services-company-text h2 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
}

.services-company-text p {
    font-size: 1.2rem;
    color: #666;
    line-height: 1.6;
}

.services-company-visual {
    flex: 1;
    display: flex;
    justify-content: center; /* Center the image within its column */
    padding: 20px;
}

.services-company-image {
    max-width: 90%;
    height: auto;
}

/* Responsive Adjustments */

/* Medium devices (768px to 991px) */
@media (max-width: 991px) {
    .services-company-overview {
        padding: 40px 5%;
    }

    .services-company-container {
        flex-direction: column; /* Stack content vertically */
    }

    .services-company-text h2 {
        font-size: 2rem;
    }

    .services-company-text p {
        font-size: 1.1rem;
    }

    .services-company-visual {
        padding: 20px 0;
    }
}

/* Small devices (576px to 767px) */
@media (max-width: 767px) {
    .services-company-overview {
        padding: 30px 5%;
    }

    .services-company-text h2 {
        font-size: 1.8rem;
    }

    .services-company-text p {
        font-size: 1rem;
    }

    .services-company-image {
        max-width: 80%;
    }
}

/* Extra small devices (<576px) */
@media (max-width: 575px) {
    .services-company-overview {
        padding: 20px 5%;
    }

    .services-company-text h2 {
        font-size: 1.6rem;
    }

    .services-company-text p {
        font-size: 0.9rem;
    }

    .services-company-image {
        max-width: 70%;
    }
}
