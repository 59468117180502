/* General Styles for all demo components */
.demo-item, .demo-item-reverse {
    margin-bottom: 40px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 15px;
}

/* Adjust the text container to be left-justified */
.demo-text {
    padding: 20px;
    max-width: 100%;
    text-align: left; /* Left-align the text */
    margin-left: 0; /* Ensure text aligns to the left */
}

/* Title styling */
.demo-title {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: left; /* Left-align the title */
    margin-left: 0; /* Ensure title aligns with the text */
}

/* Description styling */
.demo-description {
    font-size: 1.2rem;
    color: #666;
    text-align: left; /* Left-align the description */
    margin-left: 0; /* Align description with title and text */
}

/* Image container */
.demo-image {
    padding: 20px;
    display: block;
    text-align: center;
}

/* Image itself */
.demo-image img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Responsive adjustments for smaller screens */
@media (max-width: 767px) {
    .demo-image img {
        width: 80%;
    }

    .demo-title {
        font-size: 1.8rem;
        margin-left: 20px; /* Add consistent left margin for small screens */
    }

    .demo-description {
        font-size: 1.1rem;
        margin-left: 20px; /* Add left margin to align with title */
    }

    .demo-text {
        margin-left: 20px; /* Ensure text has the same left margin */
    }
}

@media (max-width: 575px) {
    .demo-image img {
        width: 70%;
    }

    .demo-title {
        font-size: 1.6rem;
        margin-left: 15px; /* Adjust left margin for extra small screens */
    }

    .demo-description {
        font-size: 1rem;
        margin-left: 15px; /* Adjust left margin for extra small screens */
    }

    .demo-text {
        margin-left: 15px; /* Ensure text has consistent left margin */
    }
}
