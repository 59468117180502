/* Services Section Base Styles */
.services-section {
    padding: 40px 0;
    background-color: white;
}

.service-icon {
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
}

h3 {
    margin-top: 20px;
    font-size: 3rem;
    color: black !important;
}

h4 {
    font-size: 1.8rem;
    color: #04283A;
    margin-top: 15px;
}

p {
    font-size: 1rem;
    color: #6c757d;
}

/* Add hover effect for icons */
.service-icon:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
}

/* Responsive Adjustments */

/* Medium devices (768px to 991px) */
@media (max-width: 991px) {
    .service-icon {
        width: 150px;
        height: 150px;
    }

    h4 {
        font-size: 1.5rem;
    }

    p {
        font-size: 0.9rem;
    }
}

/* Small devices (576px to 767px) */
@media (max-width: 767px) {
    .service-icon {
        width: 120px;
        height: 120px;
    }

    h4 {
        font-size: 1.3rem;
    }

    p {
        font-size: 0.85rem;
        max-width: 300px; /* Set a maximum width for text */
        margin: 0 auto; /* Center the text */
    }
}

/* Extra Small devices (phones, <576px) */
@media (max-width: 575px) {
    .service-icon {
        width: 100px;
        height: 100px;
    }

    h4 {
        font-size: 1.2rem;
    }

    p {
        font-size: 0.8rem;
        max-width: 250px; /* Set a smaller maximum width for extra small screens */
        margin: 0 auto; /* Center the text */
    }

    .services-section {
        padding: 20px 0;
    }
}
