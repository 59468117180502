/* Base styles for both CTA sections */
.cta-section, .cta-section-B {
    position: relative;
    width: 100vw;
    height: 45vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    text-align: center;
    padding: 20px;
}

.cta-section {
    background-color: #2B5F4A;
}

.cta-section-B {
    background-color: #04283A;
}

.cta-title {
    font-size: 3.5rem;
    margin-bottom: 20px;
    color: white !important;
}

/* Updated button styles for CTA section */
.cta-section .cta-button, .cta-section-B .cta-button {
    background-color: #ffffff;
    color: #04283A;
    font-size: 1.2rem;
    padding: 10px 20px;
    width: 250px;
    text-align: center;
    border-radius: 5px; /* Rounded corners */
    font-weight: normal; /* Regular text */
    transition: background-color 0.3s ease;
    margin-top: 20px;
    display: inline-block;
    text-decoration: none;
    border: none; /* No border */
}

.cta-section .cta-button:hover, .cta-section-B .cta-button:hover {
    background-color: #d3d3d3; /* Light gray hover color */
    color: #04283A;
}

/* Responsive Adjustments */
@media (max-width: 767px) {
    .cta-title {
        font-size: 2.5rem;
    }
}

@media (max-width: 575px) {
    .cta-title {
        font-size: 2.2rem;
    }
}
