/* src/components/Services.css */

.services-page {
    max-width: 100%; /* Ensure full width */
    margin: 0 auto;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Additional styles for responsiveness, if necessary */
@media (max-width: 768px) {
    .services-hero {
        height: auto;
        padding: 20px;
    }

    .company-overview-title,
    .services-overview-title,
    .service-process-title {
        font-size: 1.5rem;
    }

    .call-to-action-button {
        width: 100%;
        padding: 15px;
    }
}
