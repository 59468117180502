/* src/components/Footer.css */
.footer {
    position: relative;
    width: 100vw; /* Full width of the viewport */
    left: 50%;
    right: 50%;
    margin-left: -50vw; /* Move left to the edge */
    margin-right: -50vw; /* Move right to the edge */
    background-color: #343a40;
    color: #ffffff;
    padding: 5px 0;
}

.footer h5 {
    margin-bottom: 10px;
    font-size: 1.25rem;
    color: #ffffff;
    text-align: center; /* Center the About Us title */
}

.footer p {
    color: #adb5bd;
    text-align: center; /* Center the paragraph text */
}

.text-center {
    margin-top: 10px;
}
