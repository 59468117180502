.service-process {
    padding: 100px 20px;
    margin-bottom: 50px;
    text-align: center;
    background-image: url('GalaxyMap.svg');
    background-size: 180%;
    background-position: center;
    background-repeat: no-repeat;
    color: black;
}

.service-process h2 {
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #000000;
    font-weight: 700;
    letter-spacing: 1px;
}

/* Carousel Container */
.carousel-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 20px;
    position: relative;
    max-width: 100%;
    overflow: hidden; /* Keep overflow within the container */
}

.carousel-arrow {
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    color: #000000;
    padding: 10px;
    transition: transform 0.2s;
    flex-shrink: 0;
}

.carousel-arrow:hover {
    transform: scale(1.2);
}

.carousel {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    scroll-behavior: smooth;
    overflow-x: auto;
    max-width: 500px;
    width: 100%;
    padding: 0 10px;
    scrollbar-width: none;
    box-sizing: border-box;
    scroll-snap-type: x mandatory; /* Enable scroll snapping */
}

.carousel::-webkit-scrollbar {
    display: none;
}

.carousel-step {
    flex: 0 0 auto;
    width: 160px;
    text-align: center;
    transition: opacity 0.3s ease, transform 0.3s ease;
    opacity: 0.5;
    padding: 10px;
    background-color: transparent;
    scroll-snap-align: start; /* Snap steps to the left edge */
}

.carousel-step.active {
    opacity: 1;
    transform: scale(1);
    background-color: #e0e0e0;
    color: #333;
    border-radius: 8px;
}

.carousel-step h3 {
    font-size: 1rem;
    margin: 5px 0;
    white-space: normal;
    line-height: 1.2;
}

.step-content {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    height: 200px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.step-content p {
    font-size: 1.1rem;
    color: #000000;
    line-height: 1.6;
    margin: 0;
    text-align: center;
}

/* Extra Large devices (1200px and above) */
@media (min-width: 1200px) {
    .service-process {
        padding: 100px 30px;
        background-size: 180%;
    }

    .service-process h2 {
        font-size: 2.8rem;
        margin-bottom: 40px;
    }

    .carousel-step {
        width: 170px;
    }

    .step-content {
        max-width: 650px;
    }

    .step-content p {
        font-size: 1.2rem;
    }
}

/* Large devices (992px to 1199px) */
@media (max-width: 1199px) {
    .service-process {
        padding: 90px 20px;
        background-size: 210%;
    }

    .service-process h2 {
        font-size: 2.2rem;
        margin-bottom: 30px;
    }

    .carousel-step {
        width: 150px;
    }

    .step-content {
        max-width: 550px;
    }

    .step-content p {
        font-size: 1rem;
    }
}

/* Medium devices (768px to 991px) */
@media (max-width: 991px) {
    .service-process {
        padding: 80px 15px;
        background-size: 280%;
    }

    .service-process h2 {
        font-size: 2rem;
    }

    .carousel-step {
        width: 140px;
        scroll-snap-align: start; /* Apply snapping behavior */
    }

    .step-content {
        max-width: 500px;
    }

    .step-content p {
        font-size: 1rem;
    }
}

/* Small devices (576px to 767px) */
@media (max-width: 767px) {
    .service-process {
        padding: 60px 10px;
    }

    .service-process h2 {
        font-size: 1.8rem;
    }

    .carousel-container {
        max-width: 60%; /* Reduce the overall width of the carousel */
        margin: 0 auto; /* Center the carousel */
    }

    .carousel {
        max-width: 90%; /* Reduce the carousel width */
        padding: 0 10px; /* Adjust padding to keep spacing balanced */
    }

    .carousel-step {
        width: 120px;
        scroll-snap-align: start;
        padding: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .carousel-arrow {
        font-size: 1.5rem; /* Make the arrows smaller */
        padding: 5px;
    }

    .step-content {
        max-width: 400px;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .step-content p {
        font-size: 1rem;
        line-height: 1.6;
    }
}

/* Extra Small devices (<576px) */
@media (max-width: 575px) {
    .service-process {
        padding: 40px 10px;
        background-size: 280%;
    }

    .service-process h2 {
        font-size: 1.6rem;
    }

    .carousel-container {
        max-width: 65%; /* Further reduce the overall width of the carousel */
        margin: 0 auto; /* Keep the carousel centered */
    }

    .carousel {
        max-width: 85%; /* Reduce carousel width more */
        padding: 0 10px;
    }

    .carousel-step {
        width: 120px;
        scroll-snap-align: start;
        padding: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .carousel-arrow {
        font-size: 1.5rem; /* Make the arrows even smaller for XS */
        padding: 5px;
    }

    .step-content {
        max-width: 300px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .step-content p {
        font-size: 0.9rem;
        line-height: 1.6;
    }
}
