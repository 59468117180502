/* src/components/ScrollingItems.css */

.scrolling-section {
    text-align: center; /* Center-align the entire section */
    margin-top: 80px; /* Add margin to the top of the component */
    margin-bottom: 120px; /* Add margin to the bottom of the component */
    background-color: transparent; /* Revert to transparent background */
    color: #000000; /* Revert text color to black */
}

.scrolling-container {
    width: 100%; /* Full width within the section */
    padding: 20px 0; /* Add padding to prevent drop shadow from being cut off */
    overflow: visible; /* Allow the drop shadow to be fully visible */
    background-color: transparent; /* Keep the background transparent */
    position: relative; /* Relative positioning for absolute children */
}

.scrolling-track {
    display: flex; /* Arrange items in a row */
    width: calc(10 * (150px + 20px)); /* Width of one full set of 10 items including margins */
    animation: scroll 40s linear infinite; /* Continuous scrolling animation */
}

.scrolling-item {
    flex: none; /* Items should not flex or shrink */
    width: 150px; /* Fixed width for each item */
    height: 150px; /* Fixed height for each item */
    margin-right: 20px; /* Space between items */
    background-color: #ffffff; /* White background for the items */
    color: #000000; /* Black text color */
    display: flex; /* Center the text inside the item */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    border-radius: 50%; /* Make items circular */
    font-size: 1rem; /* Adjust font size to fit text */
    text-align: center; /* Center text within each item */
    padding: 10px; /* Add padding to ensure text fits within the circle */
    border: 2px solid #000000; /* Black border around each item */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3); /* Add drop shadow to items */
}

@keyframes scroll {
    0% {
        transform: translateX(0); /* Start at the normal position */
    }
    100% {
        transform: translateX(calc(-10 * (150px + 20px))); /* Move the exact width of one set */
    }
}

/* Pause the scrolling animation on hover */
.scrolling-container:hover .scrolling-track {
    animation-play-state: paused; /* Pause the animation when hovering */
}

.info-section {
    margin-top: 40px; /* Space above the text section */
}

.info-section h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: #333333; /* Dark grey text for the heading */
}

/* Updated contact button styles */
.contact-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1.2rem;
    background-color: #d3d3d3; /* Starting color: light gray */
    color: #04283A;
    text-decoration: none;
    border-radius: 5px; /* Rounded corners */
    font-weight: normal; /* Regular text */
    transition: background-color 0.3s ease;
    border: none; /* No border */
    margin-top: 40px;
}

.contact-button:hover {
    background-color: #a9a9a9; /* Rollover color: darker gray */
    color: #04283A;
}
