/* Base styles for 1200px and above */
.hero-section {
    position: relative;
    width: 100%;
    height: 70vh;
    background: url('your-background-image.svg') no-repeat center center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    text-align: center;
}

.hero-content {
    width: 100%;
}

.hero-text-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hero-title {
    margin-bottom: 10px;
    font-size: 2.4rem;
    line-height: 1.1;
    width: 60%;
    text-align: center;
}

.hero-description {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #ffffff;
    text-align: center;
}

.hero-image {
    max-width: 50%;
    height: auto;
    margin: 0 auto;
}

/* Updated button styles */
.hero-section .hero-button {
    background-color: #ffffff;
    color: #04283A;
    font-size: 1.2rem;
    padding: 10px 20px;
    width: 250px;
    text-align: center;
    border-radius: 5px; /* Updated to 5px */
    font-weight: normal; /* Regular text */
    transition: background-color 0.3s ease;
    margin-top: 20px;
    display: inline-block;
    text-decoration: none;
    border: none; /* No border */
}

.hero-section .hero-button:hover {
    background-color: #d3d3d3; /* Light gray hover color */
    color: #04283A;
}

/* Responsive adjustments */

/* Large devices (992px to 1199px) */
@media (max-width: 1199px) {
    .hero-section {
        height: auto;
        padding: 60px 40px;
    }

    .hero-title {
        font-size: 2.0rem;
        width: 70%;
    }

    .hero-description {
        font-size: 1rem;
        margin-bottom: 0px;
    }

    .hero-image {
        max-width: 50%;
    }

    .hero-section .hero-button {
        font-size: 1.0rem;
        width: 200px;
    }
}

/* Medium devices (768px to 991px) */
@media (max-width: 991px) {
    .hero-section {
        flex-direction: column;
        padding: 50px 20px;
    }

    .hero-title {
        font-size: 1.7rem;
        width: 80%;
    }

    .hero-description {
        font-size: 0.9rem;
    }

    .hero-image {
        max-width: 50%;
    }

    .hero-section .hero-button {
        font-size: 0.8rem;
        width: 180px;
    }
}

/* Small devices (576px to 767px) */
@media (max-width: 767px) {
    .hero-section {
        padding: 40px;
        height: 350px;
        background: url('background2.svg') no-repeat center center;
        background-size: cover;
    }

    .hero-title {
        font-size: 1.8rem;
        width: 200px;
    }

    .hero-description {
        font-size: 0.85rem;
    }

    /* Hide tool image */
    .hero-image {
        display: none;
    }
}

/* Extra Small devices (phones, <576px) */
@media (max-width: 575px) {
    .hero-section {
        padding: 20px;
        height: 350px;
        background: url('background2.svg') no-repeat center center;
        background-size: cover;
    }

    .hero-title {
        font-size: 1.5rem;
        width: 200px;
    }

    .hero-description {
        font-size: 0.75rem;
    }

    /* Hide tool image */
    .hero-image {
        display: none;
    }
}
